<template>
  <div class="solute-plan" style="height: 100vh;">
    <div class="mouse-wheel-slide-vertical">
      <div class="slide-container">
       <div class="slide-wrapper" ref="slidePlan">
          <div class="slide-content">
             <div class="slide-page">
                <div class="nineSystem flex-col ali-center jus-center">
                <div class="commonTitle">9大应用场景</div>
                <!-- <div class="info24">9 APPLICATION SCENARIOS</div> -->
                <div class="flex-col nineSystemContent">
                  <div class="flex-row jus-between margin-bottom-17">
                    <div class="box11 flex-col" @click="toPages(1)">
                      <img
                        class="pic4"
                        referrerpolicy="no-referrer"
                        src="@/assets/index/img3.png"
                      />
                      <div class="mod4 flex-col">
                        <span class="word30">01&nbsp;线下健康科普基地</span>
                      </div>
                    </div>

                    <div style="flex: 1" class="flex-col jus-between">
                      <div class="flex-row jus-around">
                        <div class="box11 flex-col mod5" @click="toPages(2)">
                          <img src="@/assets/index/img4.png" />
                          <div class="mod4 flex-col">
                            <span class="word30">02&nbsp;线上健康教育平台</span>
                          </div>
                        </div>
                        <div class="box11 flex-col mod7"  @click="toPages(4)">
                          <img src="@/assets/index/img5.png" />
                          <div class="mod4 flex-col">
                            <span class="word30">04&nbsp;健康社区</span>
                          </div>
                        </div>
                      </div>
                      <div class="flex-row jus-around">
                        <div class="box11 flex-col mod6" @click="toPages(3)">
                          <img src="@/assets/index/img6.png" />
                          <div class="mod4 flex-col">
                            <span class="word30">03&nbsp;健康管理干预服务</span>
                          </div>
                        </div>
                        <div class="box11 flex-col bd2" @click="toPages(5)">
                          <img src="@/assets/index/img7.png" />
                          <div class="mod4 flex-col">
                            <span class="word30">05&nbsp;健康家庭</span>
                          </div>
                        </div>

                        <div class="box11 flex-col bd4" @click="toPages(6)">
                          <img src="@/assets/index/img8.png" />
                          <div class="mod4 flex-col">
                            <span class="word30">06&nbsp;健康校园</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="box11 flex-col" @click="toPages(7)">
                      <img src="../assets/index/img9.png" alt="" />
                      <div class="mod11 flex-col">
                        <span class="word30">07&nbsp;健康企业</span>
                      </div>
                    </div>
                  </div>
                  <div class="flex-row jus-between">
                    <div class="box11 flex-col box16" @click="toPages(8)">
                      <img src="@/assets/index/img10.png" />
                      <div class="mod4 flex-col">
                        <span class="word30">08&nbsp;健康乡村</span>
                      </div>
                    </div>

                    <div class="box11 flex-col box18" @click="toPages(9)">
                      <img src="@/assets/index/img11.png" />
                      <div class="mod4 flex-col">
                        <span class="word30">09&nbsp;健康公园</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             </div>
             <div class="slide-page slide-img flex-col" v-for="item in content" :key="item.name + item.img">
               <div class="commonTitle">{{ item.name }}</div>
               <img class="webpImg" :src="item.img" alt="" srcset="">
             </div>
             <Bottom></Bottom>
          </div>
       </div>
      </div>
    </div>
      <div class="nav">
		 <div :class="currentPageIndex==index+1? 'divhover':'divh'" v-for="(item,index) in nav" :key="index" @click="tiao(index)">
			<span :class="currentPageIndex==index+1? 'spancl':'spancolor'">{{item.id}}&nbsp;</span>
			<span :class="currentPageIndex==index+1? 'spancl':'spancolor'">{{item.name}}</span>
			<span class="threespan">{{item.name}}</span>
		 </div>
	  </div>
	   <p class="right"></p>
  </div>
</template>

<script>
import BScroll from "@better-scroll/core";
import MouseWheel from "@better-scroll/mouse-wheel";
BScroll.use(MouseWheel);
import Slide from "@better-scroll/slide";
BScroll.use(Slide);

import Bottom from "../components/Bottom.vue";
var load= require('lodash');
export default {
  name: "SolutePlan",
  components: { Bottom},
  data() {
    return {
		nav:[
			{
			id:'01',
			name:'线下健康科普基地',
			di:'one'
			},
			{
			id:'02',
			name:'线上健康教育平台',
			di:'two'
			},
			{
			id:'03',
			name:'健康管理干预服务',
			di:'three'
			},
			{
			id:'04',
			name:'健康社区',
			di:'four'
			},
			{
			id:'05',
			name:'健康家庭',
			di:'five'
			},
			{
			id:'06',
			name:'健康校园',
			di:'six'
			},
			{
			id:'07',
			name:'健康企业',
			di:'seven'
			},
			{
			id:'08',
			name:'健康乡村',
			di:'eight'
			},
			{
			id:'09',
			name:'健康公园',
			di:'nine'
			},

		],
      content: [
          {img: require('@/assets/solute-plan/web1.png'), name: '线下健康科普基地'},
          {img: require('@/assets/solute-plan/web2.png'), name: '线上健康教育平台'},
          {img: require('@/assets/solute-plan/web3.png'), name: '健康管理干预服务'},
          {img: require('@/assets/solute-plan/web4.png'), name: '健康社区'},
          {img: require('@/assets/solute-plan/web5.png'), name: '健康家庭'},
          {img: require('@/assets/solute-plan/web6.png'), name: '健康校园'},
          {img: require('@/assets/solute-plan/web7.png'), name: '健康企业'},
          {img: require('@/assets/solute-plan/web8.png'), name: '健康乡村'},
          {img: require('@/assets/solute-plan/web9.png'), name: '健康公园'},
      ],
      currentIndex: 0,
      currentPageIndex: 0,
      webSocketCloseFlase:false,
      box1Data: [
        {
          title: '沉浸式',
          desc: '通过互动展示、虚拟仿真、实景模拟、人工智能应用等体验场景，建立从视觉、听觉、触觉强大的感官逼真感，带来最真实的内容体验'
        },
        {
          title: '教学性',
          desc: '将丰富系统的知识寓于妙趣横生的展示环境中，实现知识性与趣味性的结合，通过增加学习乐趣而获得专业健康知识，普及健康生活'
        },
        {
          title: '智能化',
          desc: '场馆无人化管理、能效管控、综合管控等智能化手段应用'
        },
      ],
      box2Data:[
        {
          title: '线上教学',
          desc: '专家知识讲座、健康促进推广、健康管理师/国家营养师培训等线上教学'
        },
        {
          title: '健康档案',
          desc: '通过集成智能健康检测设备或自助测评结果，收集健康数据，建立个人基础健康档案',
        },
        {
          title: '精准处方',
          desc: '基于基础健康数据分析，个性化定制健康处方'
        },
        {
          title: '线上体验',
          desc: '线上VR实景体验、互动测评、热门科普',
        },
        {
          title: '后台综合管控',
          desc: '后台即时内容发布更新，管理端可视化、数字化'
        },
        {
          title: '线上线下结合',
          desc: '依托线下实体健康科普展厅，打造线上健康科普云展厅，建立移动式的线上数字展厅，提供基于互联网络传输更便利的体验科普平台，覆盖更广区域，更多群体',
        }
      ],
      box4Data: [
        {
          title: '智能健身驿站',
          desc: '打造户外小型健身房，整个驿站配套有11站锻炼器材，有拉伸、有氧、无氧、舒缓按摩四类器材。'
        },
        {
          title: '社区健康小屋',
          desc:
          `
          特点：实用性、指导性、易用性、互动性<br />
          四大作用：<br />
          发现-危险因素  建立-健康档案 <br />
          开展-健康教育  协助-健康管理 <br />
          `
        }
      ],
      box5Data: [
        {
          title: '职业病',
          desc: '根据公司检测大数据有针对性的进行体检、健康讲堂。'
        },
        {
          title: '健康知识科普',
          desc: '线上线下健康知识科普相结合的模式建立企业健康小屋。'
        },
        {
          title: '健康饮食',
          desc: '员工食堂科学配餐，推出健康饮食。'
        },
      ],
      box6Data: [
        {
          title: '健康医疗检测',
          desc: '在乡村医务室搭建村民自我检测体系，如身高、体重、血压、心率。中老年可以把子女添加到紧急联系人，当检测数据出现异常时，立即反馈给子女。'
        },
        {
          title: '健康饮食指导',
          desc: '通过对村民慢病人员数据的采集，有针对性的提出健康饮食指导，组织村民观看科普视频，定期进行检查'
        },
        {
          title: '运动广场建设',
          desc: '在村民聚集的地方建设运动广场，配备太阳能感应灯，运动设备实时监控，记录相关数据。智能语音配置，不仅可以语音播报锻炼方法还可实时播报运动数据。'
        },
      ],
      box7Data: [
        {
          title: '沉浸式',
          desc: '通过互动展示、虚拟仿真、实景模拟、人工智能应用等体验场景，建立从视觉、听觉、触觉强大的感官逼真感，带来最真实的内容体验'
        },
        {
          title: '教学性',
          desc: '将丰富系统的知识寓于妙趣横生的展示环境中，实现知识性与趣味性的结合，通过增加学习乐趣而获得专业健康知识，普及健康生活'
        },
        {
          title: '智能化',
          desc: '场馆无人化管理、能效管控、综合管控等智能化手段应用'
        }
      ],
      box8Data: [
        {
          title: '管理后台',
          content: ['客流监控', '设备管理', '用户习惯', '健康查询']
        },
        {
          title: '智能配套',
          content: ['人脸识别', '健身驿站', '运动器材', '互动大屏']
        },
        {
          title: '健康体测',
          content: ['户外体测设备', '室内健康小屋', '心率柱', '感应器']
        },
        {
          title: '用户终端',
          content: ['微信公众号', '运动记录', '健康知识', '健康查询']
        },
      ],
      box9Data: [
        {
          icon: require('../assets/solute-plan/icon3.png'),
          desc: '将健康检测、健康评估、健康干预、健康服务、健康预防、健康定制、健康生活、健康生态一体化结合'
        },
        {
          icon: require('../assets/solute-plan/icon4.png'),
          desc: '运用云数据、云计算、云服务，为客户健康提供24小时360度全方位高品质的健康服务'
        },
        {
          icon: require('../assets/solute-plan/icon5.png'),
          desc: '为用户提供大数据查询、分析及统计全面了解和掌握自身身体状态，制定专业合理的健康调理方案，选择合理的健康产品等'
        },
        {
          icon: require('../assets/solute-plan/icon6.png'),
          desc: '尤其是慢性病患者、亚健康人群的诊前咨询和诊后追踪的健康管理服务'
        },
      ],
      userform:null,
    };
  },
  created() {
    this.slidePlan = null;
  },
  mounted() {
    this.init();
    // window.addEventListener('mousewheel', this.throttle(this.mouseWheelStart, 1000), false)
    this.getBrowserType()
    this.userfrom = window.sessionStorage.getItem("from")

      if(!this.$route.params.id){
      this.$mybus.emit('userBehavior', {from:this.userfrom,moduleLevel1:2,moduleLevel2:11})
    }

  },
  beforeDestroy() {
    this.slidePlan.destroy();
  },
  computed:{
	  fmousewheelEvent(){
		  return load.throttle(this.MouseWheelStart,500,{leading: true, trailing: false})
	  }
  },
  watch:{

  },
  methods: {
    getBrowserType() {
      var mousewheelevt = (/Firefox/i.test(navigator.userAgent))? "DOMMouseScroll" : "mousewheel"
      //判断是否是IE
      if (document.attachEvent) {
          document.attachEvent("on"+mousewheelevt, function(e) {
              this.fmousewheelEvent(e, e.wheelDelta);
          });
      }
      //FireFox(DOMMouseScroll)、Chrome、Opera、safari
      else if (document.addEventListener) {
        var this_ = this
          document.addEventListener(mousewheelevt, function(e) {
              e=e || window.event;
              if(e.detail){//Firefox
                  this_.fmousewheelEvent(e, e.detail*40);
              }
              else if(e.wheelDelta){
                  this_.fmousewheelEvent(e, -e.wheelDelta);
              }
          }, false);
      }
    },
    // fmousewheelEvent(e, delta) {
    //     this.throttle(this.mouseWheelStart(e, delta), 1000)
    // },
    toPages(e){
      console.log('dianjil---------', e)
      this.currentPageIndex = e
      this.slidePlan.goToPage(0,e, 300)
      this.$mybus.emit('userBehavior', {from:this.userfrom,moduleLevel1:2,moduleLevel2:this.currentPageIndex})

    },

    init() {
      this.slidePlan = new BScroll(this.$refs.slidePlan, {
        scrollX: false,
        scrollY: true,
        slide: {
          loop: false,
          threshold: 200,
          autoplay: false,
		  easing:{
		  				  style:'cubic-bezier(.47,0,.74,.71)'
		  }
        },
        swipeTime: 1000,
        click: true,
        probeType: 3,
        momentumLimitDistance: 1000,
        mouseWheel: false,
        bounce: true,
        momentum: true,
        preventDefault: false,
      });
      this.slidePlan.on("slidePageChanged", this._onSlidePageChanged);
      // this.slidePlan.on("scroll", this._onScrollEnd);

      if (this.$route.params.id != undefined) {
        console.log('dianjil---------', this.$route.params.id)
        this.currentPageIndex = this.$route.params.id
        this.slidePlan.goToPage(0, this.$route.params.id, 700)
      }

    },
	MouseWheelStart(e,deltaY){
		this.mouseWheelStart(e,deltaY)

    if(this.$route.path == '/solute-plan') {

      let cur = this.currentIndex
      console.log(deltaY,'daY')
      console.log(cur,'当前cur')
      if (deltaY > 0) {
        cur += 1
      } else {
        cur -= 1
        console.log(cur,'减去cur')
      }
      if (cur >= 10) {
        cur = 10
      }
      if (cur == 0) {
        cur = 0
      }
      this.currentIndex = cur

      if(cur == 0){
        this.$mybus.emit('userBehavior', {from:this.userfrom,moduleLevel1:2,moduleLevel2:11})

      }else{
        this.$mybus.emit('userBehavior', {from:this.userfrom,moduleLevel1:2,moduleLevel2:this.currentIndex})
      }

      console.log(this.currentIndex)

      // this.$mybus.emit('userBehavior', {from: this.userfrom, moduleLevel1: 1, moduleLevel2: cur})
    }

	},
    mouseWheelStart(e, deltaY) {

      console.log(this.$route.path,'路由地址')
      if(this.$route.path == '/solute-plan'){

        let currentPageIndex = this.currentPageIndex

        let dir = deltaY

        let c =document.getElementsByClassName("slide-page")
        if (this.currentPageIndex == 9 && dir > 0) {
          let a = document.querySelector(".section12")
          this.slidePlan.scrollTo(0, -(c[this.currentPageIndex].offsetTop) - a.offsetHeight,1500);
          this.toBottom = true;
          return ;
        }
        if (this.toBottom == true && dir < 0) {
          this.currentPageIndex = 9
          this.slidePlan.scrollTo(0,-(c[this.currentPageIndex].offsetTop) , 1500);
          this.toBottom = false;
          return ;
        }

        if ( dir > 0)
          currentPageIndex += 1
        else
          currentPageIndex -= 1

        if ( currentPageIndex <= 0 )
          currentPageIndex = 0
        if( currentPageIndex >= 9 )
          currentPageIndex = 9

        this.slidePlan.goToPage(0, currentPageIndex, 700)
        this.currentPageIndex = currentPageIndex
      }


    },
	tiao(index){
		this.slidePlan.goToPage(0,index+1,700)
		this.currentPageIndex=index+1
    this.currentIndex = index+1
    // console.log(this.currentPageIndex)
    this.$mybus.emit('userBehavior', {from:this.userfrom,moduleLevel1:2,moduleLevel2:this.currentPageIndex})

  },
    _onSlidePageChanged(e) {



      if (e.pageY == 9) {
        this.scrollTop4 = e.y;
      }
    },
     //节流函数
    throttle (fn, gapTime) {
      let _this = this
      return function () {
        let _nowTime = +new Date()
        if (_nowTime - _this._lastTime > gapTime || !_this._lastTime) {
          fn(...arguments)// 函数可以带参数
          _this._lastTime = _nowTime
        }
      }
    },
    _onScrollEnd() {
      console.log('---------scroll',)
    },

  },
};
</script>

<style lang="stylus" scoped>
	.threespan{
		opacity 0
		font-size: 15px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #959595;
		line-height: 17px;

	}

	.nav{
		position fixed
		top 17rem
		right 2rem
		background-color #FFFFFF

		display flex
		flex-direction column
	    align-items flex-end
	}
	.right{
		position fixed
		top 16rem
		right 1.7rem
		width: 1px;
		height: 600px;
		border: 1px solid #efefefe3 ;
	}

	.divh{
		display flex
		width: 32px
		height: 35px
		overflow hidden
		margin-top 7px
		border-left 1px solid #efefefe3
		cursor pointer
	}
	.divh:hover .threespan{
		position fixed
		opacity 1
		right 60%px
		margin-top 10px
	}

	.divhover{
	   display flex
	   width: 155px
	   height: 35px
	   overflow hidden
	   margin-top 7px
	   border-left 1px solid #efefefe3
	   background-color #019FD8
	   cursor pointer
	   animation mymove  1s infinite
	   animation-iteration-count 1
	   color #FFFFFF

	}
	@keyframes mymove{
		from{
			width:39px;
		}
		to{
			width 155px
		}
	}


	.nav>div>span:nth-child(1){
		width 32px
		height 35px
		font-size: 14px;
		font-family: DINAlternate-Bold, DINAlternate;
		font-weight: bold;

		line-height: 15px;
		margin-top: 9px;

	}
	.nav>div>span:nth-child(2){
		height 28px
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;

		line-height: 15px;
		margin-top: 9px;

	}
	.spancolor{
		color: #B3B3B3;
	}
	.spancl{
		color #FFFFFF

	}

.commonTitle {
  margin-bottom 10px
}

.right-translateX {
  &-enter-active, &-leave-active {
    transition: all 1s ease;
    // transition-dely: 3000;
  }

  &-enter {
    transform: translateX(1000px);
    opacity: 0;
  }

  &-leave-to {
    opacity: 1;
  }
}

.left-translateX {
  &-enter-active, &-leave-active {
    transition: all 1s ease;
    // transition-dely: 3000;
  }

  &-enter {
    transform: translateX(-1000px);
    opacity: 0;
  }

  &-leave-to {
    opacity: 1;
  }
}

.webpImg {
  width: 1580px;
  height: 750px;
  display: block;
}

#bottom{
  position: absolute;
  bottom: 0;
}

.lastSlideItem{
  justify-content: flex-start;
  padding-top: 20px;
}

.homeIndex {
  padding-top: 80px;
  height: 100%;
}

.mouse-wheel-slide-vertical {
  height: 100%;

  &.view {
    padding: 0;
    height: 100%;
  }

  .slide-container {
    position: relative;
    height: 100%;
    font-size: 0;
  }

  .slide-wrapper {
    height: 100%;
    overflow: hidden;

    .slide-page {
      display: inline-block;
      width: 100% !important;
      line-height: 200px;
      text-align: center;
      font-size: 26px;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
    }
    .slide-img {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .slide-content > .slide-img:nth-of-type(4) {
    background-image url('../assets/bgc-img3.png')
    background-size 100% 100%
  }

  .slide-content > .slide-img:nth-of-type(10) {
    background-image url('../assets/bgc-img9.png')
    background-size 100% 100%
  }

  .dots-wrapper {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);

    .dot {
      display: block;
      margin: 4px 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #eee;

      &.active {
        height: 20px;
        border-radius: 5px;
      }
    }
  }
}

.slideItmes {
  width: 100%;
  height: 100%;
  position: relative;
  font-family: PingFangSC-Regular, PingFang SC;

  >div {
    width: 100%;
    // height: 400px;
    position: relative;
  }

  .top1 {
    align-items: flex-end;

    .title {
      width: 1050px;
      height: 60px;
      font-size: 26px;
      font-weight: 600;
      color: #333333;
      line-height: 37px;
      text-align: left;
      margin-bottom: 20px;
      padding-left: 110px；

      .spec {
        font-size: 53px;
        font-weight: 400;
        color: #C5C5C5;
        line-height: 60px;
        margin-right: 15px;
        position: relative;

        &:before {
          content: '';
          width: 1px;
          height: 65px;
          background: rgba(0, 0, 0, 0.25);
          position: absolute;
          left: 50%;
          top: 60px;
          z-index: 6;
        }
      }

    }

    .content {
      width: 1050px;
      height: 340px;
      background: #F8F8F8;
      position: relative;
      background-image: url('../assets/solute-plan/bg1.png');
      background-repeat: no-repeat;
      background-position: right;
      padding-left: 140px;

      .icon1 {
        width: 30px;
        height: 30px;
        position: absolute;
        right: 75px;
        top: 50%;
        transform: translateY(-50%);
      }

      .img1 {
        width: 540px;
        height: 360px;
        position: absolute;
        left: -490px;
        top: -60px;
      }

      .box1 {
        width: 200px;
        height: 250px;
        background: #FCFCFC;
        border-radius: 10px;
        border: 1px solid rgba(2, 93, 145, 0.5);
        position: relative;
        margin-right: 60px;
        padding: 0 10px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 30px;
        letter-spacing: 1px;
        text-align: left;
        transform-style: preserve-3d;

        &:before {
          content: '';
          width: 200px;
          height: 250px;
          background: #FCFCFC;
          border-radius: 10px;
          border: 1px solid rgba(2, 93, 145, 0.5);
          position: absolute;
          left: 5px;
          top: 5px;
          transform: translateZ(-1px);
        }

        .box1Title {
          font-size: 20px;
          font-weight: 500;
          color: #025D91;
          line-height: 60px;
          letter-spacing: 1px;
          position: relative;
          text-align: center;

          &:before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 43px;
            width: 20px;
            height: 1px;
            background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #025D91 100%);
          }

          &:after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 43px;
            width: 20px;
            height: 1px;
            background: linear-gradient(270deg, #025D91 0%, rgba(255, 255, 255, 0) 100%);
          }

        }

        .box1Content {
          flex: 1;
          overflow: hidden;
        }

      }

    }

  }

  .top2 {
    margin-top: 30px;

    .content {
      background-image: url('../assets/solute-plan/bg2.png');
      background-position: left;

    }

    .img1 {
      left: 1000px !important;
    }

    .spec {
      margin-left: 15px;
    }

    .title {
      text-align: right;
      padding-right: 120px;
      &:before {
        right: 140px;
      }
    }
  }

  .box2Contents {
    .box2:nth-of-type(1) {
      margin-top: 25px;
    }
    .box2:nth-of-type(6){
      margin-bottom: 25px;
    }
  }

  .top2 .box2 {
    width: 760px;
    justify-content: flex-end;

    .box2Title {
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #025D91;
      line-height: 28px;
      letter-spacing: 1px;
      padding-left: 30px;
      padding-right: 30px;
      position: relative;

      &:before {
        content: '';
        width: 20px;
        height: 1px;
        background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #025D91 100%);
        position: absolute;
        left: -5px;
        top: 50%;
        transform: translateY(-50%);
      }

      &:after {
        content: '';
        width: 20px;
        height: 1px;
        background: linear-gradient(270deg, #025D91 0%, rgba(255, 255, 255, 0) 100%);
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
      }

    }

    .box2Content {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 30px;
      letter-spacing: 1px;
      width: 574px;
      text-align: left;
      margin-left: 15px;

    }

  }

  .box3OutsideDiv {
    padding-left: 80px !important;
  }

  .box4Contents {
    padding-left: 68px !important;
    padding-right: 120px;
    // >.box4:nth-of-type(2){
    //   font-size: 16px;
    // }
  }

  .box4 {
    width: 386px;
    height: 230px;
    background: #FCFCFC;
    border-radius: 10px;
    border: 1px solid rgba(2, 93, 145, 0.5);
    position: relative;
    transform-style: preserve-3d;
    padding: 15px 12px;
    text-align: left;

    &:before  {
      content: '';
      position: absolute;
      left: 5px;
      top: 5px;
      width: 386px;
      height: 230px;
      background: rgba(255, 255, 255, 1);
      border-radius: 10px;
      border: 1px solid rgba(2, 93, 145, 0.7);
      transform: translateZ(-1px);
    }

    .box4Title {
      font-size: 20px;
      font-family: FontName;
      color: #025D91;
      line-height: 44px;
      letter-spacing: 1px;
      position: relative;
      margin-bottom: 15px;

      &:before {
        content: '';
        position: absolute;
        top: 100%;
        width: 150px;
        height: 1px;
        background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #025D91 100%);
      }
    }

    .box4Content {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC !important;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 35px;
      letter-spacing: 1px;

      .spec-title {
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.6);
        line-height: 30px;
        letter-spacing: 1px;
        margin-right: 10px;

        + span {
          font-size: 16px;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: 1px;
        }
      }

      pre {
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 1px;
        font-family: PingFangSC-Regular, PingFang SC;
      }
    }

  }

  .box5OutsideDiv {
    padding-top: 67px;
    padding-left: 90px !important;

    .info1 {
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      line-height: 34px;
      letter-spacing: 1px;
      margin-bottom: 20px;

      .icon2 {
        width: 70px;
        height: 70px;
        display: block;
        margin-right: 20px;
      }

    }

  }

  .box5 {
    width: 250px;
    height: 160px;
    background: #FFFFFF;
    border-radius: 10px 0px 10px 0px;
    margin-right: 20px;
    padding: 16px 18px;
    text-align: left;

    .box5Title {
      font-size: 20px;
      font-weight: 500;
      color: #025D91;
      line-height: 28px;
      letter-spacing: 1px;
      padding-bottom: 15px;
      position: relative;
      margin-bottom: 15px;

      &:before {
        content: '';
        position: absolute;
        top: 100%;
        width: 70px;
        height: 2px;
        background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #025D91 100%);
      }

    }

    .box5Content {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 30px;
      letter-spacing: 1px;
    }
  }

  .box6OutsideDiv {
    padding-left: 240px !important;
  }

  .box6 {
    width: 600px;
    height: 97px;
    margin-bottom: 12px;
    position: relative;
    text-align: left;

    .box6Title {
      width: 250px;
      height: 46px;
      background: linear-gradient(270deg, #025D91 0%, #019FD8 100%);
      border-radius: 10px 10px 0px 0px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 22px;
      letter-spacing: 1px;
      padding-top: 5px;
      padding-left: 34px;
    }

    .box6Content {
      width: 100%;
      height: 64px;
      background: #FFFFFF;
      border-radius: 10px 0px 10px 0px;
      position: absolute;
      bottom: 0;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 24px;
      padding: 8px 20px;
    }

  }

  .box7OutsideDiv {
    padding-left: 95px !important;
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .box7 {
    width: 687px;
    justify-content: flex-end;

    .box7Title {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #019FD8;
      line-height: 28px;
      letter-spacing: 1px;
      padding-right: 40px;
      position: relative;

      &:before {
        content: '';
        width: 20px;
        height: 2px;
        background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #019FD8 100%);
        position: absolute;
        left: -30px;
        top: 14px;
      }

      &:after {
        content: '';
        width: 20px;
        height: 2px;
        background: linear-gradient(270deg, #019FD8 0%, rgba(255, 255, 255, 0) 100%);
        position: absolute;
        right: 10px;
        top: 14px;
      }
    }

    .box7Content {
      width: 572px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 30px;
      letter-spacing: 1px;
      text-align: left;
    }
  }

  .box8 {
    width: 140px;
    margin-right: 25px;

    > div {
      margin-bottom: 3px;
    }

    .box8Title {
      height: 46px;
      background: linear-gradient(270deg, #025D91 0%, #019FD8 100%);
      border-radius: 10px 10px 0px 0px;
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 46px;
      letter-spacing: 1px;
    }

    .box8Content {
      height: 40px;
      font-size: 16px;
      font-weight: 500;
      background: #FFFFFF;
      line-height: 22px;
      letter-spacing: 1px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 40px;
    }
  }

  .box9OutsideDiv {
    width: 715px !important;
    height: 255px !important;
    flex-wrap: wrap;

    .box9:nth-of-type(1):before {
      content: '';
      width: 40px;
      height: 40px;
      background: url('../assets/solute-plan/icon7.png') no-repeat;
      position: absolute;
      right: 0;
      transform: translateX(70%);
      z-index: 9;
    }

    .box9:nth-of-type(2):before {
      content: '';
      width: 40px;
      height: 40px;
      background: url('../assets/solute-plan/icon7.png') no-repeat;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) translateY(70%) rotate(90deg);
      z-index: 9;
    }

    .box9:nth-of-type(3):before {
      content: '';
      width: 40px;
      height: 40px;
      background: url('../assets/solute-plan/icon7.png') no-repeat;
      position: absolute;
      right: 0;
      transform: translateX(70%) rotate(180deg);
      z-index: 9;
    }
  }


  .box9 {
    width: 350px;
    height: 120px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid rgba(2, 93, 145, 0.2);
    padding: 20px 12px;
    position: relative;

    .icon9 {
      display: block;
      margin-right: 10px;
    }

    .box9Content {
      width: 281px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 26px;
      text-align: left;
    }
  }
}

.solute-plan {
  padding-top: 85px;
  height: 100%;
}
.nineSystem {
  height: 100%;
  background: url('../assets/index/systemBgc.png') no-repeat;
}

.nineSystemContent {
  width: 1476px;
  margin-top: 30px;

}

.info23 {
  z-index: 243;
  width: 94px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.85);
  font-size: 26px;
  font-family: PingFangSC-Semibold;
  white-space: nowrap;
  line-height: 37px;
  text-align: left;
}

.info24 {
  z-index: 244;
  width: 191px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  white-space: nowrap;
  line-height: 28px;
  margin-top: 10px;
  // text-align: left;
}

.margin-bottom-17 {
  margin-bottom: 17px;
}

.box11 {
  z-index: 343;
  height: 450px;
  background-color: rgba(216, 216, 216, 1);
  width: 300px;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 18px;

  &:hover >img {
    transform: scale(1.25);
    transition: all 0.3s ease;
  }

  &:before {
    // content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    background: linear-gradient(135deg, #2568F1 0%, rgba(123, 163, 239, 0.6) 100%);
  }

  > img {
    z-index: 6;
    width: 100%;
    height: 100%;
    display: block;
  }

  > div {
    z-index: auto;
    width: 100%;
    height: 100%;
    padding: 46px 16px;
    overflow: hidden;
    position: absolute;
    z-index: 12;

    .word30 {
      z-index: 346;
      // width: 191px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 26px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 37px;
      text-align: left;
      align-self: flex-start;
    }

    .paragraph5 {
      flex: 1;
      width: 100%;
      height: 100%;
      display: block;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      line-height: 30px;
      text-align: left;
      margin-top: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.mod7 {
  height: 234px;
  width: 520px;

  &:before {
    background: linear-gradient(135deg, #32CEC7 0%, rgba(137, 255, 249, 0.7) 100%);
  }
}

.mod5 {
  height: 234px;
  width: 320px;

  &:before {
    background: linear-gradient(135deg, #5196FA 0%, rgba(133, 173, 223, 0.7) 100%);
  }
}

.mod6 {
  height: 204px;
  width: 320px;

  &:before {

    background: linear-gradient(135deg, #1F92FE 0%, rgba(164, 213, 255, 0.75) 100%);
  }
}

.bd2 {
  height: 204px;
  width: 254px;

  &:before {
    background: linear-gradient(135deg, #5196FA 0%, rgba(133, 173, 223, 0.8) 100%);
  }
}

.bd4 {
  height: 204px;
  width: 254px;

  &:before {
    background: linear-gradient(135deg, #32CEC7 0%, rgba(137, 255, 249, 0.75) 100%);
  }
}

.box16 {
  height: 233px;
  width: 732px;

  &:before {
    background: linear-gradient(135deg, #32CEC7 0%, rgba(137, 255, 249, 0.75) 100%);
  }
}

.box18 {
  height: 233px;
  width: 732px;

  &:before {
    background: linear-gradient(135deg, #5196FA 0%, rgba(133, 173, 223, 0.85) 100%);
  }
}

</style>
